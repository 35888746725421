import { useReservations } from 'src/stores/reservations/useReservations';
import {
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordRaw,
} from 'vue-router';
import gettext from '../utils/gettext';
import { useUser } from 'stores/user/useUser';
import { PermissionsCodename } from 'src/api';
import { PaymentStatusOptions } from 'components/constants';
import { useVisit } from 'stores/visits/useVisitStore';

// for public routes, use meta tag public

const { $gettext } = gettext;
const routesMain: RouteRecordRaw[] = [
  // home redirect from back
  {
    path: '/home',
    props: true,
    redirect: (): RouteLocationRaw => {
      return { name: 'home' };
    },
    children: [],
  },
  {
    path: '/home',
    alias: $gettext('/domu'),
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/dashboard/DashboardPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('src/layouts/AppAuthLayout.vue'),
    name: 'auth',
    meta: { public: true },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('src/pages/LoginPage.vue'),
      },
      {
        path: 'signup-free',
        alias: $gettext('registrace-zdarma'),
        name: 'signupFree',
        component: () => import('src/pages/SignUpPageFree.vue'),
      },
      {
        path: 'password-reset',
        alias: $gettext('obnova-hesla'),
        name: 'resetEmail',
        component: () => import('src/pages/ResetEmailPage.vue'),
      },
      {
        path: 'resetpassword/:uid/:token',
        alias: $gettext('resetpassword/:uid/:token'),
        name: 'resetPassword', // IF CHANGED - needs to be changed in layouts/AppAuthLayout.vue as well
        props: true,
        component: () => import('src/pages/ResetPasswordPage.vue'),
      },
    ],
  },
  {
    path: '/patients',
    alias: $gettext('/pacienti'),
    component: () => import('src/layouts/PatientNavLayout.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'patients',
        component: () => import('src/pages/PatientsPage.vue'),
      },
      {
        path: 'new',
        alias: $gettext('novy'),
        name: 'new-patient',
        props: (route) => ({
          next: route.query.next,
          isFromNewVisit: route.query.isFromNewVisit,
        }),
        component: () => import('pages/patients/CreatePatientPage.vue'),
      },
      {
        path: '/waiting-patients',
        alias: $gettext('/cekajici-pacienti'),
        props: true,
        children: [
          {
            path: '',
            name: 'patients-wait',
            props: true,
            component: () => import('src/pages/WaitingPatientsPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('novy'),
            name: 'new-waiting-patient',
            props: true,
            component: () =>
              import('pages/patients/CreateWaitingPatientPage.vue'),
          },
        ],
      },
      {
        path: ':patientId',
        props: true,
        children: [
          {
            path: 'patient-card',
            alias: $gettext('karta-pacienta'),
            props: true,
            children: [
              {
                path: '',
                name: 'patient-detail',
                props: (route) => ({
                  patientId: route.params.patientId,
                  receiptId: route.query.receiptId,
                  isFromCreatePatient: route.query.isFromCreatePatient,
                }),
                component: () =>
                  import('src/pages/patients/PatientDetailPage.vue'),
              },
              {
                path: 'edit',
                alias: $gettext('upravit'),
                name: 'edit-patient',
                props: true,
                component: () =>
                  import('src/pages/patients/UpdatePatientPage.vue'),
              },
              {
                path: 'add-subscription',
                alias: $gettext('pridat-permanentku'),
                name: 'new-patient-ticket',
                props: (route) => ({
                  patientId: route.params.patientId,
                  next: route.query.next,
                }),
                component: () =>
                  import('pages/tickets/NewPatientTicketPage.vue'),
              },
              {
                path: 'edit-subscription/:ticketId',
                alias: $gettext('upravit-permanentku/:ticketId'),
                name: 'edit-patient-ticket',
                props: (route) => ({
                  ticketId: route.params.ticketId,
                  patientId: route.params.patientId,
                }),
                component: () =>
                  import('src/pages/tickets/EditPatientTicketPage.vue'),
              },
            ],
          },
          {
            path: 'anamnesis',
            alias: $gettext('anamneza'),
            name: 'patient-medical-history',
            props: true,
            component: () =>
              import('src/pages/patients/PatientMedicalHistoryPage.vue'),
          },
          {
            path: 'patient-visits',
            alias: $gettext('navstevy-pacienta'),
            props: true,
            children: [
              {
                path: '',
                name: 'patient-visits',
                props: true,
                component: () =>
                  import('src/pages/patients/PatientVisitsPage.vue'),
              },
              {
                path: ':visitId',
                props: true,
                beforeEnter: async (to, from, next): Promise<void> => {
                  const visitId = to.params.visitId;
                  const visitStore = useVisit();
                  await visitStore.getVisit(visitId.toString());
                  if (!visitStore.visitDetail && visitStore.errors) {
                    return next({
                      name: 'patient-visits',
                      params: {
                        patientId: to.params.patientId,
                      },
                    });
                  }
                  return next();
                },
                children: [
                  {
                    path: '',
                    name: 'visit-detail',
                    props: (route) => ({
                      visitId: route.params.visitId,
                      patientId: route.params.patientId,
                      receiptId: route.query.receiptId,
                    }),
                    component: () =>
                      import('src/pages/patients/VisitDetailPage.vue'),
                  },
                  {
                    path: 'pay',
                    alias: $gettext('zaplatit'),
                    name: 'create-payment',
                    props: (route) => ({
                      visitId: route.params.visitId,
                      patientId: route.params.patientId,
                      next: route.query.next,
                      fromGroup: route.query.fromGroup === 'true',
                    }),
                    component: () => import('pages/patients/PaymentPage.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'photos',
            alias: $gettext('fotografie'),
            props: true,
            children: [
              {
                path: '',
                name: 'photo-list',
                props: true,
                component: () =>
                  import('src/pages/patients/PhotosListPage.vue'),
              },
              {
                path: 'comparator',
                alias: $gettext('porovnavac'),
                name: 'photo-compare',
                props: true,
                component: () =>
                  import('src/pages/patients/PhotosComparerPage.vue'),
              },
            ],
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'new-visit-patient',
            props: (route) => ({
              userId: route.query.userId,
              patientId: route.query.patientId,
              time: route.query.time,
              next: route.query.next,
              isFromCreatePatient: route.query.isFromCreatePatient,
              initialRoomId: route.query.initialRoomId,
            }),
            component: () => import('src/pages/VisitCreatePage.vue'),
          },
          {
            path: 'performances',
            alias: $gettext('vykony'),
            name: 'ft-tickets',
            children: [
              {
                path: '',
                name: 'ft-ticket-list',
                props: true,
                component: () =>
                  import('src/pages/patients/insurance/FTTicketListPage.vue'),
              },
              {
                path: 'new-ft-voucher',
                alias: $gettext('novy-ft-poukaz'),
                name: 'new-ft-ticket',
                props: (route) => ({
                  patientId: route.params.patientId,
                  ftCloneId: route.query.ftCloneId,
                }),
                component: () =>
                  import('src/pages/patients/insurance/CreateFtTicketPage.vue'),
              },
              {
                path: 'edit-ft-voucher/:ticketId',
                alias: $gettext('upravit-ft-poukaz/:ticketId'),
                name: 'edit-ft-ticket',
                props: (route) => ({
                  ticketId: route.params.ticketId,
                  patientId: route.params.patientId,
                }),
                component: () =>
                  import('src/pages/patients/insurance/UpdateFtTicketPage.vue'),
              },
            ],
          },
        ],
      },
      {
        path: ':patientId/visit-descriptions',
        alias: $gettext(':patientId/popisy-navstev'),
        name: 'visit-descriptions',
        props: true,
        component: () =>
          import('src/pages/patients/PatientVisitDescriptionsPage.vue'),
      },
    ],
  },
  {
    path: '/calendar',
    alias: $gettext('/kalendar'),
    component: () => import('src/layouts/AppCalendarNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'calendar',
        redirect: { name: 'calendar-users' },
      },
      {
        path: 'physiotherapists',
        alias: $gettext('fyzioterapeuti'),
        name: 'calendar-users',
        props: true,
        component: () => import('pages/calendar/CalendarPage.vue'),
      },
      {
        path: 'rooms',
        alias: $gettext('mistnosti'),
        name: 'calendar-rooms',
        props: true,
        component: () => import('pages/calendar/CalendarRoomsPage.vue'),
      },
      {
        path: 'planning',
        alias: $gettext('planovani'),
        name: 'calendar-planning',
        props: true,
        component: () => import('pages/calendar/CalendarPlanningPage.vue'),
      },
      {
        path: 'room-planning',
        alias: $gettext('planovani-mistnosti'),
        name: 'calendar-room-planning',
        props: true,
        component: () => import('pages/calendar/RoomPlaningCalendarPage.vue'),
      },
    ],
  },
  {
    path: '/actions',
    alias: $gettext('/akce'),
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: 'add-leave',
        alias: $gettext('pridat-volno'),
        name: 'create-time-off',
        props: (route) => ({
          userId: route.query.userId,
          timeOffDate: route.query.timeOffDate,
          timeOffDateEnd: route.query.timeOffDateEnd,
          calendarSelectedDate: route.query.calendarSelectedDate,
          next: route.query.next,
        }),
        component: () => import('pages/office/CreateTimeOffPage.vue'),
      },
      {
        path: 'edit-leave',
        alias: $gettext('upravit-volno'),
        name: 'update-time-off',
        props: (route) => ({
          userId: route.query.userId,
          timeOffId: route.query.timeOffId,
          calendarSelectedDate: route.query.calendarSelectedDate,
          next: route.query.next,
        }),
        component: () => import('pages/office/UpdateTimeOffPage.vue'),
      },
      {
        path: 'new-visit',
        alias: $gettext('nova-navsteva'),
        name: 'new-visit',
        props: (route) => ({
          userId: route.query.userId,
          patientId: route.query.patientId,
          time: route.query.time,
          next: route.query.next,
          isFromCreatePatient: route.query.isFromCreatePatient,
          initialRoomId: route.query.initialRoomId,
          unfinishedVisit: route.query.unfinishedVisit === 'true',
        }),
        component: () => import('src/pages/VisitCreatePage.vue'),
      },
      {
        path: 'new-group-visit',
        alias: $gettext('nova-skupinova-navsteva'),
        name: 'new-group-visit',
        props: (route) => ({
          userId: route.query.userId,
          time: route.query.time,
          next: route.query.next,
          initialRoomId: route.query.initialRoomId,
        }),
        component: () =>
          import('src/pages/group-visit/GroupVisitCreatePage.vue'),
      },
    ],
  },
  {
    path: '/print',
    alias: $gettext('/tisk'),
    component: () => import('src/layouts/PrintLayout.vue'),
    children: [
      {
        path: 'card/:patientId',
        alias: $gettext('karta/:patientId'),
        name: 'print-patient',
        props: (route) => ({
          patientId: route.params.patientId,
          selectedVisitsIds: route.query.selectedVisitsIds,
          visitsToDate: route.query.visitsToDate,
        }),
        component: () => import('src/pages/print/PrintPatient.vue'),
      },
      {
        path: 'visit/:patientId/:visitId',
        alias: $gettext('navsteva/:patientId/:visitId'),
        name: 'print-visit',
        props: true,
        component: () => import('pages/print/PrintVisit.vue'),
      },
      {
        path: 'visits-confirmation/:patientId',
        alias: $gettext('potvrzeni-pece/:patientId'),
        name: 'print-visits-confirmation',
        props: (route) => ({
          patientId: route.params.patientId,
          visits: route.query.visits,
        }),
        component: () => import('pages/print/PrintVisitConfirmation.vue'),
      },
    ],
  },
  {
    path: '/statistics',
    alias: $gettext('/statistiky'),
    component: () => import('src/layouts/AppStatsNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'stats',
        redirect: { name: 'visit-stats' },
      },
      {
        path: 'visits',
        alias: $gettext('navstevy'),
        name: 'visit-stats',
        component: () => import('src/pages/stats/VisitStatsPage.vue'),
      },
      {
        path: 'Memberships',
        alias: $gettext('Permanentky'),
        name: 'ticket-stats',
        component: () => import('src/pages/stats/TicketStatsPage.vue'),
      },
      {
        path: 'finance',
        alias: $gettext('finance'),
        name: 'finance-stats',
        component: () => import('src/pages/stats/FinanceStatsPage.vue'),
      },
      {
        path: 'Physiotherapists',
        alias: $gettext('fyzioterapeuti'),
        name: 'users-stats',
        component: () => import('src/pages/stats/UsersStatsPage.vue'),
      },
    ],
  },
  {
    path: '/storage',
    alias: $gettext('/sklad'),
    component: () => import('layouts/AppStockNavLayout.vue'),
    beforeEnter(to, from, next): void {
      const userStore = useUser();
      if (!userStore.user?.company_allowed_warehouse) {
        return next({
          name: 'home',
        });
      }
      return next();
    },
    children: [
      {
        path: '',
        name: 'stock',
        redirect: { name: 'supplies' },
      },
      {
        path: 'resources',
        alias: $gettext('zasoby'),
        children: [
          {
            path: '',
            name: 'supplies',
            component: () => import('src/pages/stock/StockPage.vue'),
          },
          {
            path: 'stocking',
            alias: $gettext('naskladneni'),
            name: 'stock-up',
            component: () => import('src/pages/stock/CreateSupplyPage.vue'),
          },
          {
            path: 'inventory',
            alias: $gettext('inventura'),
            name: 'stock-check',
            component: () => import('src/pages/stock/StockCheckPage.vue'),
          },
        ],
      },
      // TODO: Stock receipts & goods movement will be implemented in the next dev iteration
      // {
      //   alias: $gettext('doklady'),
      //   name: 'stock-receipts',
      //   component: () => import('src/pages/stock/StockReceiptsPage.vue'),
      // },
      // {
      //   alias: $gettext('pohyb-zbozi'),
      //   name: 'goods-movement',
      //   component: () => import('src/pages/stock/GoodsMovementPage.vue'),
      // },
      {
        path: 'items',
        alias: $gettext('polozky'),
        children: [
          {
            path: '',
            name: 'stock-items',
            component: () => import('src/pages/stock/StockItemsPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'stock-item-create',
            component: () => import('src/pages/stock/CreateStockItemPage.vue'),
          },
          {
            path: ':stockItemId/edit',
            alias: $gettext(':stockItemId/upravit'),
            name: 'stock-item-update',
            props: true,
            component: () => import('src/pages/stock/UpdateStockItemPage.vue'),
          },
        ],
      },
      {
        path: 'Suppliers',
        alias: $gettext('dodavatele'),
        children: [
          {
            path: '',
            name: 'suppliers',
            component: () => import('src/pages/stock/SuppliersPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('novy'),
            name: 'supplier-create',
            props: (route) => ({
              next: route.query.next, // handle redirects after the supplier is either created or user goes back from the create form
            }),
            component: () => import('src/pages/stock/CreateSupplierPage.vue'),
          },
          {
            path: ':supplierId/edit',
            alias: $gettext(':supplierId/upravit'),
            name: 'supplier-update',
            props: true,
            component: () => import('src/pages/stock/UpdateSupplierPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/settings',
    alias: $gettext('/nastaveni'),
    component: () => import('layouts/AppSettingsNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'settings',
        redirect: { name: 'basic-settings' },
      },
      {
        path: 'currently',
        alias: $gettext('obecne'),
        children: [
          {
            path: '',
            name: 'basic-settings',
            component: () => import('src/pages/settings/SettingsPage.vue'),
          },
          {
            path: 'sms',
            name: 'sms-settings',
            component: () =>
              import('src/pages/settings/sms/SMSSettingsPage.vue'),
          },
          {
            path: 'email',
            name: 'email-settings',
            component: () =>
              import('src/pages/settings/email/EmailSettingsPage.vue'),
          },
          {
            path: 'gdpr',
            name: 'gdpr-content',
            component: () =>
              import('src/pages/settings/gdpr/GDPRContentPage.vue'),
          },
        ],
      },
      {
        path: 'reservation',
        alias: $gettext('rezervace'),
        name: 'reservations',
        component: () => import('src/pages/settings/ReservationsPage.vue'),
      },
      {
        path: 'templates',
        alias: $gettext('sablony'),
        children: [
          {
            path: '',
            name: 'templates',
            component: () => import('src/pages/settings/TemplatesPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'new-template',
            props: (route) => ({
              next: route.query.next,
            }),
            component: () =>
              import('src/pages/settings/CreateTemplatePage.vue'),
          },
          {
            path: 'edit/:templateId',
            alias: $gettext('upravit') + '/:templateId',
            name: 'update-template',
            props: (route) => ({
              templateId: route.params.templateId,
              next: route.query.next,
            }),
            component: () =>
              import('src/pages/settings/UpdateTemplatePage.vue'),
          },
        ],
      },
      {
        path: 'billing-information',
        alias: $gettext('fakturacni-udaje'),
        name: 'billing-data',
        component: () => import('src/pages/settings/BillingDataPage.vue'),
      },
      {
        path: 'contact-details',
        alias: $gettext('kontaktni-udaje'),
        name: 'contact-information',
        component: () =>
          import('src/pages/settings/ContactInformationPage.vue'),
      },
      {
        path: 'reviews',
        name: 'reviews-list',
        component: () => import('src/pages/settings/ReviewsListPage.vue'),
      },
      {
        path: 'email-configuration',
        name: 'email-configuration',
        component: () =>
          import('src/pages/settings/EmailConfigurationPage.vue'),
      },
    ],
  },
  {
    path: '/office',
    alias: $gettext('/ordinace'),
    component: () => import('layouts/AppOfficeNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'office',
        redirect: { name: 'receipts' },
      },
      {
        path: 'services',
        alias: $gettext('sluzby'),
        children: [
          {
            path: '',
            name: 'services',
            component: () => import('pages/office/ServicesPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'services-new',
            component: () =>
              import('pages/office/services/ServiceCreatePage.vue'),
          },
          {
            path: ':serviceId/edit',
            alias: $gettext(':serviceId/upravit'),
            name: 'services-update',
            props: true,
            component: () =>
              import('pages/office/services/ServiceUpdatePage.vue'),
          },
          {
            path: 'category',
            alias: $gettext('kategorie'),
            children: [
              {
                path: 'new',
                alias: $gettext('nova'),
                name: 'service-groups-new',
                component: () =>
                  import('pages/office/services/ServiceGroupCreatePage.vue'),
                props: (route) => ({
                  next: route.query.next,
                }),
              },
              {
                path: ':serviceGroupId/edit',
                alias: $gettext(':serviceGroupId/upravit'),
                name: 'service-groups-update',
                props: true,
                component: () =>
                  import('pages/office/services/ServiceGroupUpdatePage.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'Exercises',
        alias: $gettext('cviky'),
        children: [
          {
            path: '',
            name: 'exercises-overview',
            component: () =>
              import(
                'pages/office/exercises/ExercisesOverviewExercisePage.vue'
              ),
          },
          {
            path: 'new',
            alias: $gettext('novy'),
            name: 'exercise-new',
            component: () =>
              import('pages/office/exercises/ExercisesCreateExercisePage.vue'),
          },
          {
            path: 'category',
            alias: $gettext('kategorie'),
            children: [
              {
                path: 'new',
                alias: $gettext('nova'),
                name: 'exercises-groups-new',
                component: () =>
                  import('pages/office/exercises/ExerciseGroupCreatePage.vue'),
                props: (route) => ({
                  next: route.query.next,
                  mainCategoryId: route.query.mainCategoryId,
                  createMainCategoryOnly: route.query.createMainCategoryOnly,
                }),
              },
              {
                path: 'edit',
                alias: $gettext('upravit'),
                name: 'exercise-groups-update',
                props: (route) => ({
                  next: route.query.next,
                  mainCategoryId: route.query.mainCategoryId,
                  createMainCategoryOnly: route.query.createMainCategoryOnly,
                  exerciseGroupId: route.query.exerciseGroupId,
                }),
                component: () =>
                  import('pages/office/exercises/ExerciseGroupUpdatePage.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'Memberships',
        alias: $gettext('Permanentky'),
        children: [
          {
            path: '',
            name: 'permanent-tickets',
            component: () => import('pages/office/TicketsPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'ticket-create',
            component: () => import('pages/office/TicketsCreatePage.vue'),
          },
          {
            path: ':ticketId/edit',
            alias: ':ticketId/' + $gettext('upravit'),
            name: 'ticket-update',
            props: true,
            component: () => import('pages/office/TicketsUpdatePage.vue'),
          },
        ],
      },
      {
        path: 'Physiotherapists',
        alias: $gettext('Fyzioterapeuti'),
        children: [
          {
            path: '',
            name: 'users',
            component: () => import('pages/office/UsersPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('novy'),
            name: 'create-user',
            component: () => import('src/pages/users/NewUserPage.vue'),
          },
          {
            path: ':userId/edit',
            alias: $gettext(':userId/upravit'),
            name: 'edit-user',
            props: true,
            component: () => import('src/pages/users/UpdateUserPage.vue'),
          },
          {
            path: ':userId/office-hours',
            alias: $gettext(':userId/ordinacni-hodiny'),
            name: 'working-hours',
            props: true,
            component: () => import('src/pages/users/WorkingHoursPage.vue'),
          },
        ],
      },
      {
        path: 'payments',
        alias: $gettext('platby'),
        children: [
          {
            path: '',
            name: 'receipts',
            component: () =>
              import('pages/office/receipts/ReceiptsListPage.vue'),
          },
          {
            path: ':receiptId',
            children: [
              {
                path: 'edit',
                alias: $gettext('upravit'),
                name: 'update-receipt',
                props: true,
                component: () =>
                  import('pages/office/receipts/ReceiptUpdatePage.vue'),
              },
            ],
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'create-receipt',
            props: true,
            component: () =>
              import('pages/office/receipts/ReceiptCreatePage.vue'),
          },
        ],
      },
      {
        path: 'performances',
        alias: $gettext('vykony'),
        children: [
          {
            path: '',
            name: 'medical-actions-list',
            component: () =>
              import('pages/office/medical-actions/MedicalActionsListPage.vue'),
          },
        ],
      },
      {
        path: 'subscription',
        alias: $gettext('predplatne'),
        children: [
          {
            path: '',
            name: 'subscriptions',
            component: () =>
              import('pages/office/subscriptions/SubscriptionsListPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('nove'),
            name: 'create-subscription',
            component: () =>
              import('pages/office/subscriptions/CreateSubscriptionPage.vue'),
          },
          {
            path: ':subscriptionId',
            children: [
              {
                path: '',
                name: 'subscription-detail',
                props: (route) => ({
                  subscriptionId: route.params.subscriptionId,
                  receiptId: route.query.receiptId,
                }),
                component: () =>
                  import(
                    'pages/office/subscriptions/SubscriptionsDetailPage.vue'
                  ),
              },
              {
                path: 'edit',
                alias: $gettext('upravit'),
                name: 'update-subscription',
                props: true,
                component: () =>
                  import(
                    'pages/office/subscriptions/UpdateSubscriptionPage.vue'
                  ),
              },
              {
                path: 'invoices',
                alias: $gettext('faktury'),
                children: [
                  {
                    path: 'new',
                    alias: $gettext('nova'),
                    name: 'create-subscription-payment',
                    props: true,
                    component: () =>
                      import(
                        'pages/office/subscriptions/CreateSubscriptionPaymentPage.vue'
                      ),
                  },
                  {
                    path: ':subscriptionPaymentId/edit',
                    alias: ':subscriptionPaymentId/' + $gettext('upravit'),
                    name: 'update-subscription-payment',
                    props: true,
                    component: () =>
                      import(
                        'pages/office/subscriptions/UpdateSubscriptionPaymentPage.vue'
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'rooms',
        alias: $gettext('mistnosti'),
        children: [
          {
            path: '',
            name: 'rooms',
            component: () => import('pages/office/rooms/RoomsListPage.vue'),
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'create-room',
            component: () => import('pages/office/rooms/RoomsCreatePage.vue'),
          },
          {
            path: ':roomId',
            children: [
              {
                path: 'edit',
                alias: $gettext('upravit'),
                name: 'update-room',
                props: true,
                component: () =>
                  import('pages/office/rooms/RoomsUpdatePage.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'branches',
        alias: $gettext('pobocky'),
        name: 'branches',
        component: () => import('pages/office/branches/BranchesPage.vue'),
      },
    ],
  },
  {
    path: '/insurances',
    alias: $gettext('/pojistovny'),
    name: 'insurance',
    redirect: { name: 'insurance-batches-list' },
    component: () => import('src/layouts/AppInsuranceCompaniesNavLayout.vue'),
    beforeEnter: (to, from, next): void => {
      const userStore = useUser();
      if (
        !userStore.user?.company_allowed_insurance ||
        !userStore.handleUserHasPermission(
          PermissionsCodename.VIEW_INSURANCES_TAB
        )
      ) {
        return next({
          name: 'home',
        });
      }
      return next();
    },
    children: [
      {
        path: 'settings',
        alias: $gettext('nastaveni'),
        name: 'insurance-settings',
        component: () =>
          import('pages/insurance-companies/InsuranceSettingsPage.vue'),
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.LIST_MY_COMPANY_INSURANCE_SETTINGS
            )
          ) {
            return next({
              name: 'home',
            });
          }
          return next();
        },
      },
      {
        path: 'benefits',
        alias: $gettext('davky'),
        name: 'insurance-batches',
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.GET_K_BATCH_LIST
            )
          ) {
            return next({
              name: 'insurance-applicants', // this is here to allow workers access insurances tab
            });
          }
          return next();
        },
        children: [
          {
            path: '',
            name: 'insurance-batches-list',
            component: () =>
              import(
                'pages/insurance-companies/insurance-batches/InsuranceBatchListPage.vue'
              ),
          },
          {
            path: 'new',
            alias: $gettext('nova'),
            name: 'insurance-batch-create',
            component: () =>
              import(
                'pages/insurance-companies/insurance-batches/InsuranceOriginalBatchCreatePage.vue'
              ),
          },
          {
            path: ':kBatchId',
            children: [
              {
                path: 'detail',
                alias: $gettext('detail'),
                name: 'insurance-batch-detail',
                props: (route) => ({
                  kBatchId: route.params.kBatchId,
                }),
                component: () =>
                  import(
                    'pages/insurance-companies/insurance-batches/InsuranceBatchDetailPage.vue'
                  ),
              },
              {
                path: 'new-repair',
                alias: $gettext('nova-opravna'),
                name: 'insurance-corrective-batch-create',
                props: (route) => ({
                  kBatchId: route.params.kBatchId,
                  next: route.query.next,
                }),
                component: () =>
                  import(
                    'pages/insurance-companies/insurance-batches/InsuranceCorrectiveBatchCreatePage.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'workplace',
        alias: $gettext('pracoviste'),
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.GET_COMPANY_INSURANCE_WORKSPACE_LIST
            )
          ) {
            return next({
              name: 'home',
            });
          }
          return next();
        },
        children: [
          {
            path: 'new',
            alias: $gettext('nove'),
            name: 'insurance-company-workspace-create',
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceCompanyWorkspaceCreatePage.vue'
              ),
          },
          {
            path: ':companyWorkspaceId/add-insurance',
            alias: $gettext(':companyWorkspaceId/pridat-pojistovnu'),
            name: 'insurance-create',
            props: (route) => ({
              companyWorkspaceId: route.params.companyWorkspaceId,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceCreatePage.vue'
              ),
          },
          {
            path: ':companyWorkspaceId/edit-insurance/:insuranceContractId',
            alias: $gettext(
              ':companyWorkspaceId/upravit-pojistovnu/:insuranceContractId'
            ),
            name: 'insurance-update',
            props: (route) => ({
              insuranceContractId: route.params.insuranceContractId,
              companyWorkspaceId: route.params.companyWorkspaceId,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceUpdatePage.vue'
              ),
          },
          {
            path: '',
            name: 'insurance-list',
            props: (route) => ({
              newWorkspace: route.query.newWorkspace,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceListPage.vue'
              ),
          },
          {
            path: ':companyWorkspaceId/edit',
            alias: $gettext(':companyWorkspaceId/upravit'),
            name: 'insurance-company-workspace-update',
            props: (route) => ({
              companyWorkspaceId: route.params.companyWorkspaceId,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceCompanyWorkspaceUpdatePage.vue'
              ),
          },
        ],
      },
      {
        path: 'requester',
        alias: $gettext('zadatele'),
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.GET_INSURANCE_WORKSPACE_LIST
            )
          ) {
            return next({
              name: 'home',
            });
          }
          return next();
        },
        children: [
          {
            path: '',
            name: 'insurance-applicants',
            component: () =>
              import(
                'pages/insurance-companies/insurance-applicants/InsuranceApplicantListPage.vue'
              ),
          },
          {
            path: 'new',
            alias: $gettext('novy'),
            name: 'insurance-applicant-create',
            props: (route) => ({
              next: route.query.next,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-applicants/InsuranceApplicantCreatePage.vue'
              ),
          },
          {
            path: ':insuranceApplicantId/edit',
            alias: $gettext(':insuranceApplicantId/upravit'),
            name: 'insurance-applicant-update',
            props: true,
            component: () =>
              import(
                'pages/insurance-companies/insurance-applicants/InsuranceApplicantUpdatePage.vue'
              ),
          },
        ],
      },
    ],
  },
  // calendar sync redirect from back
  {
    path: '/profile/synchronize-calendar',
    redirect: (): RouteLocationRaw => {
      return { name: 'calendar-sync' };
    },
  },
  {
    path: '/profile',
    alias: $gettext('/profil'),
    component: () => import('src/layouts/AppProfileNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('src/pages/profile/ProfilePage.vue'),
      },
      {
        path: 'payments',
        alias: $gettext('platby'),
        name: 'payments',
        component: () => import('src/pages/profile/PaymentsPage.vue'),
      },
      {
        path: 'rate',
        alias: $gettext('tarif'),
        name: 'subscription-plan',
        component: () => import('src/pages/SubscriptionPage.vue'),
      },
      {
        path: 'calendar-sync',
        alias: $gettext('synchronizace-kalendare'),
        name: 'calendar-sync',
        component: () => import('src/pages/profile/CalendarSyncPage.vue'),
      },
    ],
  },
  {
    path: '/history-events',
    alias: $gettext('/historie-akci'),
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'action-history',
        component: () => import('src/pages/ActionHistoryPage.vue'),
      },
    ],
  },
  {
    path: '/messages-history',
    alias: $gettext('/historie-zprav'),
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'messages-history',
        component: () => import('pages/HistoryEmailsAndSmsPage.vue'),
      },
    ],
  },
  // redirect from back after payment
  {
    path: '/payments',
    name: 'payments-redirect',
    props: true,
    component: () => import('layouts/AppLayout.vue'),
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      const paymentStatus = to.query.payment_status;
      if (paymentStatus === PaymentStatusOptions.PAID) {
        return { name: 'successfull-payment' };
      } else if (paymentStatus === PaymentStatusOptions.REJECTED) {
        return { name: 'failed-payment' };
      } else {
        return { name: 'home' }; // or redirect to a default/fallback route
      }
    },
    children: [],
  },
  {
    path: '/payment',
    alias: $gettext('/platba'),
    component: () => import('layouts/AppLayout.vue'),
    children: [
      {
        path: 'thank-you',
        alias: $gettext('dekujeme'),
        name: 'successfull-payment',
        component: () => import('src/pages/ThankYouPage.vue'),
      },
      {
        path: 'error',
        alias: $gettext('chyba'),
        name: 'failed-payment',
        component: () => import('src/pages/RejectedPage.vue'),
      },
    ],
  },
  {
    path: '/expand-registry-free',
    alias: $gettext('/rozsireni-kartoteky-zdarma'),
    component: () => import('layouts/AppFullWidthLayout.vue'),
    children: [
      {
        path: '',
        name: 'freemium-limit-upgrade',
        component: () => import('src/pages/ShareAppPage.vue'),
      },
    ],
  },
  // gdpr redirect from back
  {
    path: '/gdpr/agreement/:patientId',
    props: true,
    redirect: (): RouteLocationRaw => {
      return { name: 'gdpr-consent' };
    },
    children: [],
  },
  {
    path: '/reviews/:companyId',
    component: () => import('layouts/ReviewsLayout.vue'),
    redirect: { name: 'visit-review' },
    props: true,
    meta: {
      public: true,
    },
    children: [
      {
        path: 'visit/:visitId',
        name: 'visit-review',
        props: true,
        component: () => import('src/pages/reviews/VisitReviewPage.vue'),
      },
      {
        path: 'thank-you',
        name: 'successful-review',
        props: true,
        component: () => import('src/pages/reviews/ThankYouReviewPage.vue'),
      },
    ],
  },
  {
    path: '/group-visits/',
    alias: $gettext('/skupinove-navstevy/'),
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: ':groupVisitId',
        props: (route) => ({
          groupVisitId: route.params.groupVisitId,
          receiptId: route.query.receiptId,
        }),
        name: 'group-visit-detail',
        component: () => import('pages/group-visit/GroupVisitPage.vue'),
      },
    ],
  },
  {
    path: '/impersonate',
    component: () => import('pages/ImpersonatePage.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/guide',
    alias: $gettext('/pruvodce/'),
    component: () => import('layouts/TutorialLayout.vue'),
    children: [
      {
        path: '',
        name: 'guide-themes-list',
        component: () => import('pages/interactive-guide/InteractiveGuide.vue'),
      },
      {
        path: '/guide-gateway',
        alias: $gettext('/rozcestnik-pruvodce/'),
        name: 'guide-gateway',
        component: () =>
          import('pages/interactive-guide/InteractiveGuideGateway.vue'),
      },
    ],
  },
];

const officesSubdomainRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '',
    name: 'home',
    meta: { public: true, patientPortal: true },
    component: () => import('layouts/AboutLayout.vue'),
    children: [
      {
        path: '/',
        name: 'about',
        component: () => import('pages/about/AboutPage.vue'),
      },
    ],
  },
  {
    path: '',
    name: 'patient-portal-auth',
    meta: { public: true, patientPortal: true },
    component: () => import('layouts/PatientPortalAuthLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'login' },
      },
      {
        path: 'login',
        name: 'login',
        component: () =>
          import('pages/patient-portal/PatientPortalLoginPage.vue'),
      },
      {
        path: 'registration',
        name: 'patient-signup',
        component: () =>
          import('src/pages/patient-portal/PatientPortalSignUpPage.vue'),
      },
      {
        path: 'reset-password',
        name: 'patient-reset-email',
        component: () =>
          import('src/pages/patient-portal/PatientPortalResetEmailPage.vue'),
      },
    ],
  },
  // reservation redirect from back after payment
  {
    path: '/reservation/4-step/:visit_id?',
    alias: '/reservations/4-step/:visit_id?',
    props: true,
    meta: {
      public: true,
      reservations: true,
    },
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      if (to.query.payment_status === PaymentStatusOptions.REJECTED) {
        return { name: 'reservations-error' };
      }
      return { name: 'reservation-step-confirmation' };
    },
    children: [],
  },
  // if reservation address contains parameter *service*, try to redirect to the second step
  {
    path: '/reservations',
    alias: ['/rezervace', '/reservation'],
    redirect: '/reservations',
    props: true,
    children: [
      {
        path: '',
        name: 'reservation-layout',
        props: true,
        meta: {
          public: true,
          reservations: true,
        },
        redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
          if (to.query.service) {
            const { service, therapist, ...rest } = to.query;

            return {
              name: 'reservation-step-date-time-selection',
              params: {
                preselectedService: Array.isArray(service)
                  ? service[0]
                  : service || null,
                preselectedTherapist: Array.isArray(therapist)
                  ? therapist[0]
                  : therapist || null,
              },
              query: rest,
            };
          }

          return {
            name: 'reservation-step-entry',
          };
        },
        component: () => import('layouts/ReservationLayout.vue'),
        children: [
          {
            path: '1-step',
            alias: '1-krok',
            name: 'reservation-step-entry',
            props: true,
            component: () => import('pages/reservation/StepEntryPage.vue'),
          },
          {
            path: 'diagnosis',
            alias: 'diagnoza',
            name: 'reservation-step-diagnostic-selection',
            props: true,
            component: () =>
              import('pages/reservation/StepDiagnosisSelectionPage.vue'),
          },
          {
            path: '2-step/:preselectedService?/:preselectedTherapist?',
            alias: '2-krok/:preselectedService?/:preselectedTherapist?',
            name: 'reservation-step-date-time-selection',
            props: true,
            component: () =>
              import('pages/reservation/StepDateTimeSelectionPage.vue'),
            beforeEnter: (to, from, next): void => {
              const reservationStore = useReservations();
              if (
                !to.params.preselectedService &&
                !reservationStore.hasSecondStepAccess
              ) {
                return next({
                  name: 'reservation-step-entry',
                });
              }
              return next();
            },
          },
          {
            path: '3-step',
            alias: '3-krok',
            name: 'reservation-step-personal-data',
            props: true,
            component: () =>
              import('pages/reservation/StepPersonalDataPage.vue'),
            beforeEnter: (to, from, next): void => {
              const reservationStore = useReservations();
              if (!reservationStore.hasThirdStepAccess) {
                return next({
                  name: 'reservation-step-date-time-selection',
                });
              }
              return next();
            },
          },
          {
            path: '4-step/:visit_id?',
            alias: '4-krok/:visit_id?',
            name: 'reservation-step-confirmation',
            props: true,
            component: () =>
              import('pages/reservation/StepConfirmationPage.vue'),
            beforeEnter: async (to, from, next): Promise<void> => {
              const reservationStore = useReservations();
              const visitId = to.query.visit_id || to.params.visit_id;
              if (visitId) {
                await reservationStore.GetVisitReservation(visitId.toString());
              }
              if (!reservationStore.hasFourthStepAccess) {
                return next({
                  name: 'reservation-step-entry',
                });
              }
              return next();
            },
          },
          {
            path: 'cancellation',
            alias: 'zruseni',
            name: 'reservation-cancellation',
            component: () =>
              import('src/pages/reservation/CancelReservationPage.vue'),
            beforeEnter: (to, from, next): void => {
              if (from.name !== 'reservation-step-confirmation') {
                return next({
                  name: 'reservation-step-entry',
                });
              }
              return next();
            },
          },
          {
            path: 'error',
            name: 'reservations-error',
            props: true,
            component: () =>
              import('src/pages/reservation/ReservationErrorPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    name: 'patient-portal',
    component: () => import('layouts/PatientPortalLayout.vue'),
    meta: { patientPortal: true },
    children: [
      {
        path: '/home',
        alias: $gettext('/domu'),
        name: 'home',
        redirect: { name: 'patient-user-visits' },
      },
      {
        path: '/my-profiles',
        alias: $gettext('/moje-profily'),
        children: [
          {
            path: '',
            name: 'my-profiles',
            component: () =>
              import('pages/patient-portal/PatientProfilesPage.vue'),
          },
        ],
      },
      {
        path: '/my-details',
        alias: $gettext('/moje-udaje'),
        children: [
          {
            path: '',
            name: 'my-logs',
            component: () =>
              import('pages/patient-portal/PatientUserProfilePage.vue'),
          },
        ],
      },
      {
        path: '/my-visits',
        alias: $gettext('/moje-navstevy'),
        children: [
          {
            path: '',
            name: 'patient-user-visits',
            component: () =>
              import('pages/patient-portal/PatientUserVisits.vue'),
          },
        ],
      },
      {
        path: '/exercises',
        props: true,
        redirect: (): RouteLocationRaw => {
          return { name: 'exercises' };
        },
        children: [],
      },
      {
        path: '/exercises',
        alias: $gettext('/cviky'),
        children: [
          {
            path: '',
            name: 'exercises',
            component: () => import('pages/patient-portal/ExercisesPage.vue'),
          },
          {
            path: ':exerciseId',
            name: 'exercise-detail',
            props: (route) => ({
              indexInExecutionOrder: route.query.indexInExecutionOrder,
            }),
            component: () =>
              import('pages/patient-portal/ExercisesDetailPage.vue'),
          },
        ],
      },
    ],
  },
];

const sharedRoutes: RouteRecordRaw[] = [
  {
    path: '/gdpr',
    component: () => import('layouts/GdprConsentLayout.vue'),
    meta: {
      public: true,
    },
    children: [
      {
        path: ':companyId',
        name: 'gdpr-readonly',
        props: true,
        component: () =>
          import('pages/gdpr-consent/GdprConsentReadonlyPage.vue'),
      },
      {
        path: 'agreement/:patientId',
        alias: ['souhlas/:patientId', '/gdpr-souhlas/:patientId'],
        name: 'gdpr-consent',
        props: true,
        component: () => import('pages/gdpr-consent/GdprConsentPage.vue'),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: 'error-not-found',
    meta: { public: true },
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export { routesMain, officesSubdomainRoutes, sharedRoutes };
